import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SalaryChart = () => {
  // 完整的工资数据，排除年终奖和十三薪
  const rawData = [
    // 2021年数据
    { year: '2021', month: '2021-04', salary: 23090.50 },
    { year: '2021', month: '2021-05', salary: 22762.35 },
    { year: '2021', month: '2021-06', salary: 24070.41 },
    { year: '2021', month: '2021-07', salary: 21785.00 },
    { year: '2021', month: '2021-08', salary: 21785.00 },
    { year: '2021', month: '2021-09', salary: 25260.86 },
    { year: '2021', month: '2021-10', salary: 21672.50 },
    { year: '2021', month: '2021-11', salary: 20402.51 },
    { year: '2021', month: '2021-12', salary: 23159.75 },

    // 2022年数据
    { year: '2022', month: '2022-01', salary: 23644.75 },
    { year: '2022', month: '2022-02', salary: 23915.50 },
    { year: '2022', month: '2022-03', salary: 21912.50 },
    { year: '2022', month: '2022-04', salary: 146022.50 },
    { year: '2022', month: '2022-05', salary: 26190.00 },
    { year: '2022', month: '2022-06', salary: 26625.00 },
    { year: '2022', month: '2022-07', salary: 25594.24 },
    { year: '2022', month: '2022-08', salary: 25654.24 },
    { year: '2022', month: '2022-09', salary: 25614.24 },
    { year: '2022', month: '2022-10', salary: 25614.24 },
    { year: '2022', month: '2022-11', salary: 25634.24 },
    { year: '2022', month: '2022-12', salary: 29631.02 },

    // 2023年数据
    { year: '2023', month: '2023-01', salary: 31081.02 },
    { year: '2023', month: '2023-02', salary: 27831.02 },
    { year: '2023', month: '2023-03', salary: 28011.02 },
    { year: '2023', month: '2023-04', salary: 207221.08 },
    { year: '2023', month: '2023-05', salary: 28460.39 },
    { year: '2023', month: '2023-06', salary: 27034.30 },
    { year: '2023', month: '2023-07', salary: 26095.45 },
    { year: '2023', month: '2023-08', salary: 26583.48 },
    { year: '2023', month: '2023-09', salary: 26003.48 },
    { year: '2023', month: '2023-10', salary: 26003.48 },
    { year: '2023', month: '2023-11', salary: 25096.65 },
    { year: '2023', month: '2023-12', salary: 32766.97 },

    // 2024年数据
    { year: '2024', month: '2024-01', salary: 34081.23 },
    { year: '2024', month: '2024-02', salary: 29251.41 },
    { year: '2024', month: '2024-03', salary: 28846.42 },
    { year: '2024', month: '2024-04', salary: 28485.71 },
    { year: '2024', month: '2024-05', salary: 30530.16 },
    { year: '2024', month: '2024-06', salary: 29983.48 },
    { year: '2024', month: '2024-07', salary: 30031.43 },
    { year: '2024', month: '2024-08', salary: 31891.43 },
    { year: '2024', month: '2024-09', salary: 29991.43 },
    { year: '2024', month: '2024-10', salary: 29941.43 },
    { year: '2024', month: '2024-11', salary: 28485.71 },
  ];

  // 排除异常值（如年终奖）
  const filteredData = rawData.filter(item => item.salary < 50000);

  // 按时间排序
  const sortedData = filteredData.sort((a, b) => {
    return new Date(a.month) - new Date(b.month);
  });

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={sortedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis domain={[20000, 50000]} />
          <Tooltip 
            formatter={(value, name, props) => [
              `${value.toFixed(2)} CNY`, 
              `${props.payload.year}年 ${props.payload.month.split('-')[1]}月`
            ]}
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="salary" 
            name="月度工资"
            stroke="#8884d8" 
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const App = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1 style={{ marginBottom: '20px', textAlign: 'center' }}>工资走势图</h1>
      <SalaryChart />
    </div>
  );
};

export default App;